// details.tsx
import * as React from 'react';
import { useLoaderData } from 'react-router-dom';
import { ISearchInterface, IFacilityInterface, IMatchInterface } from '../@types/context';
import { SearchContext } from '../components/context/SearchContext';
import { MatchContext } from '../components/context/MatchContext';

import FacilityDetails from '../components/facility/FacilityDetails/FacilityDetails';
import { Header } from '../components/branding/Header';
import { Footer } from '../components/branding/Footer';

export async function loader({params}: any) {
  const facilityid = params.facility;
  // UtilsService.consoledev('facilityid', facilityid);

  return { facilityid };
}

export default function Details() {
  const params = useLoaderData() as any;
  const facilityid = params.facilityid;

  const { facilitySearch, descriptionSearch, codeSearch } = React.useContext(SearchContext) as ISearchInterface;
  const { stateview } = React.useContext(MatchContext) as IMatchInterface;
  // UtilsService.consoledev('Details', search, searchState);

  //UtilsService.consoledev('response', facilityid);
  return (
    <div>
      { !stateview &&
        <Header/>
      }
    <main className="main-wrapper">
      <FacilityDetails key={facilityid} facilityid={facilityid}/>
    </main>

    </div>
  );
}
