// Items.tsx

import * as React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';

// Done in index.html and modified a style
//import 'react-data-grid/lib/styles.css';
import DataGrid, { SelectColumn } from 'react-data-grid';
import type { Column, SortColumn, SortDirection } from 'react-data-grid';

import {
  ScatterChart,
  BarChart,
  Scatter,
  Bar,
  XAxis,
  YAxis,
  ZAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
import { ISearchInterface, IMatchInterface, IFacilityInterface, IItemInterface, IChargeInterface } from '../../../@types/context';
import { SearchContext } from '../../context/SearchContext';
import { MatchContext } from '../../context/MatchContext';

import Charge from '../Charge/Charge';

import UtilsService from "../../../services/utils.service";

import "./styles.css";

import { MdInfoOutline } from "react-icons/md";

import { v4 as uuidv4 } from 'uuid';
// import '../../../styles.css'

interface TooltipProps {
  active?: boolean;
  payload?: any;
  label?: any;
};

interface Row {
  id: string;
  selected: boolean;
  ppc_payer: string;
  ppc_plan: string;
  charge_setting: string;
  charge_billing_class: string;
  charge_modifier: string;
  ppc_negotiated_charge: string;
  ppc_negotiated_charge_formatted: string;
  charge: IChargeInterface;
  color: string;
}

/**
 * If a row is selected in the grid, remember this choice in case the facility is viewed again.
 */
interface StickyPayer {
  ppc_payer: string;
  ppc_plan: string;
}

/**
 * Keep track of any selected rows by facility
 */
type StickyByFacility = {
  [facilityid: string]: StickyPayer;
}


type InfoProps = {
  charge: IChargeInterface;
};

/**
 * fields that are displayed in the info tooltip. Add other fields here as well
 * as in Info (a few lines down).
 */
const infodata = ['ppc_estimated_amountt', 'ppc_negotiated_algorithm', 'ppc_negotiated_methodology', 'ppc_negotiated_percentage', 'item_generic_notes', 'item_extra_fields', 'ppc_additional_payer_notes']

// Very similar code in FacilitySearch.tsx. Keep in sync
const Info: React.FC<InfoProps> = ({charge }) => {
  const estimated_amount_formatted = UtilsService.formatCurrency(charge.ppc_estimated_amount);

  const infofields: any = {
    //'Setting': charge.charge_setting,
    //'Billing Class': charge.charge_billing_class,
    'Estimated Amount' : estimated_amount_formatted,
    'Negotiated Algorithm' : charge.ppc_negotiated_algorithm,
    'Negotiated Methodology' : charge.ppc_negotiated_methodology,
    'Negotiated Percentage' : charge.ppc_negotiated_percentage,
    'Generic Notes' : charge.item_generic_notes,
    'Extra Fields' : charge.item_extra_fields,
    'Additional Payer Notes' : charge.ppc_additional_payer_notes
  }
  let fields : string[] = Object.values(infofields);
  fields = fields.filter(x => x && x.trim().length > 0);

  let hasinfo = fields.some(x => x?.length>0);
  const content: string[] = [];

  if (hasinfo) {
    for (const key in infofields) {
      if (infofields[key]?.length > 0) {
        // Some of these values might be json. Parse them and write each separately
        const value = infofields[key];
        if (value.startsWith('{') && value.endsWith('}')) {
          try {
            const json = JSON.parse(infofields[key]);
            if (json) {
              for (const k in json) {
                // Don't write if the value is null or empty
                if (json[k]?.length > 0) {
                  content.push(`${k} = ${json[k]}`);
                }
              }
            }
          }
          catch {
            // On error, just write the value
            content.push(`${key} = ${infofields[key]}`);
          }
        }
        else {
          // Write the value as is
          content.push(`${key} = ${infofields[key]}`);
        }
      }
    }

    if (content.length == 0) {
      hasinfo = false;
    }
  }

  // Add revenue code (from item_codes) if found
  if (charge.item_codes) {
    try {
      const codes: any[] = JSON.parse(charge.item_codes);
      const rc = codes.find(x => x[0] == 'RC');
      if (rc) {
        content.push(`RC = ${rc[1]}`);
      }
    }
    catch {
      // Ignore
    }
  }


  return (
    <span>
    { hasinfo &&
      <a data-tooltip-id={'ttCode'} data-tooltip-html={`<div class="hover-block">${content.join('<br/>')}</div>`}>
       <MdInfoOutline/></a>
    }
    </span>
  )
};



const renderSelectedIcon = ({row}: {row: Row}) => {
  const image = row.selected ? `url('../images/Bookmark-2.svg')` : `url('../images/Bookmark.svg')`;

  return <div className="selectedpayer" style={{ backgroundImage: image }} />;
}

const renderInfoIcon = ({row}: {row: Row}) => {
  return <Info key={'i'+row.id} charge={row.charge}/>;
}


/**
 * Prevent csv from running multiple times
 */
let lastcsvrun = 0;

/**
 * Keep track of selected rows by facility
 */
const selectedRowsByFacility: StickyByFacility = {};

const ChargesDataGrid = () => {
  const { sessionid } = React.useContext(SearchContext) as ISearchInterface;
  const {selectedFacility, chargeMatches, hasSetting, hasBillingClass, hasModifiers, hasNotes, filter_setting, filter_billingclass, filter_modifiers, saveCsvCharges, error, stateview, listview } = React.useContext(MatchContext) as IMatchInterface;
  const datagridRef = React.useRef(null);

  const staticcolumns: Column<Row>[] = [
    { key: 'selected', name: '', width: 25, renderCell: renderSelectedIcon},
    { key: 'ppc_payer', name: 'Payer', resizable: true, sortable: true},
    { key: 'ppc_plan', name: 'Plan', resizable: true, sortable: true},
    { key: 'charge_setting', name: 'Setting', resizable: true},
    { key: 'charge_billing_class', name: 'Billing Class', resizable: true},
    { key: 'charge_modifier', name: 'Modifier', resizable: true},
    { key: 'ppc_negotiated_charge_formatted', name: 'Charge', resizable: true, sortable: true,
      cellClass: (row) => {
      switch (row?.charge?.charge_color) {
        case 'green': return 'greencell';
        case 'yellow': return 'yellowcell';
        case 'red': return 'redcell';
        default: return 'nocolorcell';
      }
    }},
    { key: 'info', name: '', width: 20, renderCell: renderInfoIcon}
  ];


  const [columns, setColumns] = React.useState<Column<Row>[]>(staticcolumns);


  const createRows = (): Row[] => {
    const facilityid: string = selectedFacility?.id || '';

    let displayany = false;
    let rows = chargeMatches.map((charge: IChargeInterface) => {
      const ppc_payer = charge.ppc_payer ? charge.ppc_payer : '';
      const ppc_plan = charge.ppc_plan ? charge.ppc_plan : '';
      const charge_setting = charge.charge_setting;
      const charge_billing_class = charge.charge_billing_class;
      const charge_modifier = charge.item_modifier;
      const charge_color = charge.charge_color;

      // Set selected row if it matches
      let selected = false;
      if (facilityid in selectedRowsByFacility) {
        const sticky = selectedRowsByFacility[facilityid];
        if (sticky.ppc_payer === ppc_payer && sticky.ppc_plan === ppc_plan) {
          UtilsService.consoledev('Sticky row found', sticky);
          selected = true;
        }
      }

      // Discard charges that are blank or n/a, unless they have notes
      let info = false;
      for (const field of infodata) {
        if ((charge as any)[field]?.length > 0) {
          info = true;
          displayany = true;
          break;
        }
      }
      // console.log('info', info);

      const nc = charge.ppc_negotiated_charge?.toLowerCase()?.trim();
      if (nc && nc.length > 0 && !nc.includes('n/a') && !nc.includes('na')) {
        displayany = true;
      }

      // A special case is when there is no negotiated_charge, but there is a negotiated_percentage
      //console.log('charges', charge.ppc_negotiated_charge, charge.ppc_negotiated_percentage);
      if (charge.ppc_negotiated_charge?.length == 0 && charge.ppc_negotiated_percentage?.length > 0) {
        const p = `${charge.ppc_negotiated_percentage}%`;
        charge.ppc_negotiated_charge = p;
        charge.ppc_negotiated_charge_formatted = p;
      }

      return {
        id: charge.charge_id as string,
        selected,
        ppc_payer,
        ppc_plan,
        charge_color,
        charge_setting,
        charge_billing_class,
        charge_modifier,
        ppc_negotiated_charge: charge.ppc_negotiated_charge,
        ppc_negotiated_charge_formatted: charge.ppc_negotiated_charge_formatted,
        charge,
        info,
        color: ''
      }
    });

    rows = rows.filter(row => {
      const nc = row.ppc_negotiated_charge?.toLowerCase()?.trim();
      return row.info || (nc && nc.length > 0 && !nc.includes('n/a') && !nc.includes('na'));
    });

    // Sort rows by Payer.
    rows.sort((a, b) => {
      return a.ppc_payer.localeCompare(b.ppc_payer);
    });

    // Determine the color coding of charges. If unknown, no color is used
    let charges: number[] = [];
    for (const row of rows) {
      const c = parseFloat(row.ppc_negotiated_charge);
      if (!isNaN(c) && c > 0) {
        charges.push(c);  
      }
    }
    charges.sort((a, b) => a - b);

    // If there are no rows, create a placeholder with a message
    if (!displayany) {
      rows = [{
        id: uuidv4(),
        selected: false,
        ppc_payer: '',
        ppc_plan: '',
        charge_setting: '',
        charge_billing_class: '',
        charge_modifier: '',
        ppc_negotiated_charge: 'No payers have reported a charge for this item',
        ppc_negotiated_charge_formatted: 'No payers have reported a charge for this item',
        charge: {} as IChargeInterface,
        info: false,
        color: 'red',
        charge_color: 'red'
      }];
    }

/*
    const mincharge = Math.min(...charges);
    const maxcharge = Math.max(...charges);
    // console.log('charges', charges, mincharge, maxcharge);

    let mingreen = 0, maxgreen = 0, minyellow = 0, maxyellow = 0, minred = 0, maxred = 0;
    if (mincharge == maxcharge && mincharge > 0) {
      // All values are the same
      mingreen = mincharge;
      maxgreen = maxcharge;
    }
    else if (mincharge > 0 && maxcharge > 0) {
      mingreen = mincharge;
      maxgreen = mincharge + (maxcharge - mincharge) * 0.25;
      minyellow = maxgreen;
      maxyellow = mincharge + (maxcharge - mincharge) * 0.75;
      minred = maxyellow;
      maxred = maxcharge; 
    }

    // console.log('colors', mingreen, maxgreen, minyellow, maxyellow, minred, maxred);
*/

    // Move rows with no payer to the end
    //console.log('rows', rows);
    const blanks = rows.filter((row) => row.ppc_payer.length === 0);
    const nonblanks = rows.filter((row) => row.ppc_payer.length > 0);
    rows  = [...nonblanks, ...blanks];

    // Color code the rows
/*
    rows = rows.map((row) => {
      const c = parseFloat(row.ppc_negotiated_charge);
      if (isNaN(c) || c <= 0) {
        row.color = '';
      }
      else if (c >= mingreen && c < maxgreen) {
        row.color = 'green';
      }
      else if (c >= minyellow && c < maxyellow) {
        row.color = 'yellow';
      }
      else if (c >= minred && c <= maxred) {
        row.color = 'red';
      }
      return row;
    });
*/

    return rows;
  };
  const [rows, setRows] = React.useState(createRows);
  const [sortColumns, setSortColumns] = React.useState<readonly SortColumn[]>([]);

/*
  // Re-build the CSV data
  React.useEffect(() => {
    const now = Date.now();
    const delta = now - lastcsvrun;
    lastcsvrun = now;

    if (delta < 1000) {
      UtilsService.consoledev('Too close to run csv again', delta);
      return;
    }

    UtilsService.consoledev('*****Rebuilding csv', chargeMatches.length);
    buildCsv(chargeMatches);
  }, [chargeMatches]);

  */


  // Re-build the rows
  React.useEffect(() => {
    UtilsService.consoledev('Rebuilding rows', chargeMatches.length);
    const r = createRows();
    setRows(r);

    // Modify the columns based upon the data
    const newcolumns = [...staticcolumns];
    if (!hasNotes) {
      newcolumns.splice(7, 1);
    }
    if (!hasModifiers) {
      newcolumns.splice(5, 1);
    }
    if (!hasBillingClass) {
      newcolumns.splice(4, 1);
    }
    if (!hasSetting) {
      newcolumns.splice(3, 1);
    }

    setColumns(newcolumns);

  }, [chargeMatches]);

  // Also response to the setting, billing class, and modifier filters
  React.useEffect(() => {
    // Modify the columns based upon the data
    const newcolumns = [...staticcolumns];
    if (!hasNotes) {
      newcolumns.splice(7, 1);
    }
    if (!hasModifiers || filter_modifiers.length == 0) {
      newcolumns.splice(5, 1);
    }
    if (!hasBillingClass) {
      newcolumns.splice(4, 1);
    }
    if (!hasSetting) {
      newcolumns.splice(3, 1);
    }

    setColumns(newcolumns);

  }, [hasSetting, hasBillingClass, hasModifiers, hasNotes, filter_modifiers]);
  


  const rowKeyGetter = (row: any) => row.id;

  type Comparator = (a: Row, b: Row) => number;

  const getComparator = (sortColumn: string): Comparator => {
    // The formatted column is displayed, but sorted happens on the original column
    if (sortColumn == 'ppc_negotiated_charge_formatted') {
      sortColumn = 'ppc_negotiated_charge';
    }



    switch (sortColumn) {
      case 'ppc_payer':
      case 'ppc_plan':
      case 'charge_billing_class':
        return (a, b) => {
          const aval: string = (a as any)[sortColumn];
          const bval: string = (b as any)[sortColumn];
          return (aval ? aval : '').localeCompare(bval ? bval : '');
        };
      case 'ppc_negotiated_charge':
        // Always sort blank or null values to the end
        
        return (a, b) => {
          let astr = (a as any)[sortColumn]?.replace('$', '')?.replace(',', '') || '';
          let bstr = (b as any)[sortColumn]?.replace('$', '')?.replace(',', '') || '';
          const aval = parseFloat(astr);
          const bval = parseFloat(bstr);
          if (isNaN(aval)) astr = '';
          if (isNaN(bval)) bstr = '';
          // console.log(`Comparing '${astr}' to '${bstr}'`);
          if (astr.length == 0 && bstr.length == 0) return 0;
          if (astr.length == 0) return 1;
          if (bstr.length == 0) return -1;

          return (aval - bval);
        };
      default:
        throw new Error(`unsupported sortColumn: "${sortColumn}"`);
    }
  }

  const sortedRows = React.useMemo((): readonly Row[] => {
    // Always run, even if there are no sorted columns because of filtering
    let sorted = [...rows];

    if (sortColumns.length > 0) {
      sorted = [...rows].sort((a, b) => {
        for (const sort of sortColumns) {
          const comparator = getComparator(sort.columnKey);
          const compResult = comparator(a, b);
          if (compResult !== 0) {
            return sort.direction === 'ASC' ? compResult : -compResult;
          }
        }
        return 0;
      });


      // UtilsService.consoledev('sortColumns', sortColumns.map(x => x.columnKey));
      if (sortColumns.map(x => x.columnKey).includes('ppc_negotiated_charge')) {
        // Move blank charges to the end of the list, regardless of sort order
        sorted = sorted.sort((a, b) => {
          const acharge = a.ppc_negotiated_charge.replace('$', '').replace(',', '');
          const bcharge = b.ppc_negotiated_charge.replace('$', '').replace(',', '');
          const acheck = a.ppc_negotiated_charge === '' || isNaN(parseFloat(acharge));
          const bcheck = b.ppc_negotiated_charge === '' || isNaN(parseFloat(bcharge));
          if (acheck && bcheck) return 0;
          if (acheck) return 1;
          if (bcheck) return -1;
          return 0;
        });
      }

    }


    // If there are selected items, move them to the top
    const selected = sorted.filter(x => x.selected);
    if (selected) {
      sorted = sorted.filter(x => !x.selected);
      sorted.unshift(...selected);
    }

    // Hide rows using filters. A selected row is always shown
    //const setting = (filter_setting && filter_setting.includes('ip') && filter_setting.includes('op')) ? '' : filter_setting;
    //const billingclass = (filter_billingclass && filter_billingclass.includes('facility') && filter_billingclass.includes('professional')) ? '' : filter_billingclass;
    const setting = filter_setting;
    const billingclass = filter_billingclass;
    //UtilsService.consoledev('setting', setting.length, setting);
    //UtilsService.consoledev('billingclass', billingclass.length, billingclass);
    sorted = sorted.filter((row) => {
      if (row.selected) return true;

      if (row.charge_setting.length > 0) {
        const settinglc = row.charge_setting.toLowerCase();
        switch (setting) {
          case '':
            return false;
          case 'ip':
            if (!settinglc.includes('ip') && !settinglc.includes('inpatient')) {
              return false;
            }
            break;
          case 'op':
            if (!settinglc.includes('op') && !settinglc.includes('outpatient')) {
              return false;
            }
            break;
          default:
            break;
        }
      }


      if (row.charge_billing_class.length > 0) {
        switch (billingclass) {
          case '':
            return false;
          case 'both':
            break;
          case 'facility':
            if (!row.charge_billing_class.toLowerCase().includes('facility')) {
              return false;
            }
            break;
          case 'professional':
            if (!row.charge_billing_class.toLowerCase().includes('professional')) {
              return false;
            }
            break;
          default:
            break;
        }
      }

      return true;
    });

    //UtilsService.consoledev('sorted has ', sorted.length, 'rows');
    // console.log(sorted);

    // Format charges
    //sorted = sorted.map((row) => {
    //  row.ppc_negotiated_charge = UtilsService.formatCurrency(row.ppc_negotiated_charge);
    //  return row;
    //});

    // For settings, map ip=>inpatient and op=>outpatient
    sorted = sorted.map((row) => {
      if (row.charge_setting === 'ip') row.charge_setting = 'inpatient';
      else if (row.charge_setting === 'op') row.charge_setting = 'outpatient';
      return row;
    });


    return sorted;
  }, [rows, sortColumns, filter_setting, filter_billingclass]);
  

  /**
   * Build the data for generating a csv file
   */
  /*
  const buildCsv = (matches: IChargeInterface[]) => {
    const headers = [];
    const datarows = [];

    // Find the column names by checking the info
    const cols = ['payer', 'plan', 'setting', 'billing class', 'negotiated charge', 'negotiated methodology', 'negotiated percentage', 'negotiated algorithm'];
    const icols = ['ppc_payer', 'ppc_plan', 'charge_setting', 'charge_billing_class', 'ppc_negotiated_charge', 'ppc_negotiated_methodology', 'ppc_negotiated_percentage', 'ppc_negotiated_algorithm'];
    for (const row of matches) {
      const datarow: any = {};
      for (let i=0; i<cols.length; i++) {
        const value: string = (row as any)[icols[i]] || '';
        datarow[cols[i]] = value;
      }
      datarows.push(datarow);
    }

    for (const col of cols) {
      headers.push({label: col, key: col});
    }

    // Sort datarows by payer
    datarows.sort((a, b) => {
      return a.payer.localeCompare(b.payer);
    });

    //console.log('buildCsv', headers, datarows);
    saveCsvCharges(JSON.stringify(headers), JSON.stringify(datarows));
  }
*/

  const facilityid: string = selectedFacility?.id || '';

  return (
    <DataGrid
      ref={datagridRef} 
      columns={columns}
      rows={sortedRows}
      rowKeyGetter={rowKeyGetter}
      onRowsChange={setRows}
      sortColumns={sortColumns}
      onSortColumnsChange={setSortColumns}
      className="fill-grid rdg-light"
      style={{resize: 'both' }}
      // defaultColumnOptions={{width: '1fr'}}
      onCellClick={(args, event) => {
        console.log('cellclick', args);
        if (args.column.key === 'selected') {
          // Change the selected column
          event.preventGridDefault();
          args.selectCell(true);


          // UtilsService.consoledev('selected', args.row.selected, args.row.ppc_payer, args.row.ppc_plan);
          let newrows: Row[] = JSON.parse(JSON.stringify(rows)) as Row[];
          newrows.forEach((row) => {
            row.selected = false;
          });

          if (args.row.selected) {
            // Turn off all selected flags
            //console.log('Turn off selected', args.row);
            delete selectedRowsByFacility[facilityid];
          }
          else {
            //console.log('Turn on new', args.row);
            // Toggle the selected flag for all other columns
            newrows.forEach((row) => {
              if (row.ppc_payer === args.row.ppc_payer && row.ppc_plan === args.row.ppc_plan) {
                row.selected = true;
              }
            });

            selectedRowsByFacility[facilityid] = {
              ppc_payer: args.row.ppc_payer,
              ppc_plan: args.row.ppc_plan
            };


          }

          // Move all the selected rows to the top
          const selected = newrows.filter(x => x.selected);
          if (selected && selected.length > 0) {
            newrows = newrows.filter(x => !x.selected);
            newrows.unshift(...selected);
          }

          setRows(newrows);
          UtilsService.consoledev('selected', newrows);
        }
      }}
    />
  );

}

const CustomTooltip: React.FC<TooltipProps> = ({active, payload, label }) => {
  //UtilsService.consoledev(`CustomTooltip: active=${active}, payload=${payload}, label=${label}`)
  //UtilsService.consoledev('payload', JSON.stringify(payload))
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip" style={{color: '#0059A7'}}>
        <p className="label">{`${payload[0].payload.name} : $${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};

const Charges = () => {
  const { sessionid } = React.useContext(SearchContext) as ISearchInterface;
  const {chargeMatches, error, showgraph, stateview, listview } = React.useContext(MatchContext) as IMatchInterface;
  //const [currentSettings, setCurrentSettings] = useState<FacilitySearchSettings>(emptySettings);


  if (!sessionid || sessionid.length == 0) {
    return (
      null
    );
  }

  // Render error
  let errorstring = null;
  if (error && error.length > 0) {
    if (error === 'resultsexceeded') {
      errorstring = 'Too many results. Narrow your search.';
    }
    else if (error === 'smallsearch') {
      errorstring = 'Enter at least 3 characters to search.'
    }
  }

  // Get list of charges to display
  const datapoints: any[] = [];
  const labels: any[] = [];
  for (const charge of chargeMatches) {
    if (charge.ppc_negotiated_charge?.includes('%')) {
      // Do not show percentages
      continue;
    }
    const c = parseFloat(charge.ppc_negotiated_charge?.replace('$', '').replace(',', ''));
    if (!isNaN(c) && c > 0) {
      const name = (charge.ppc_plan?.length > 0) ? charge.ppc_payer + ': ' + charge.ppc_plan : charge.ppc_payer;
      datapoints.push({name, x: c, y: 7, color: charge.charge_color});
      labels.push({name, value: charge.ppc_negotiated_charge_formatted, unit: ''})
    }
  }
  //console.log('LABELS', labels);

  const datasets = [
    {id: 1, label: '', data: datapoints}
  ];

  const CustomBar = (props:any) => {
    // Map the color name to the color
    let color = props.fill; // Default color
    if (props.payload.color) {
      switch (props.payload.color) {
        case 'green':
          color = '#D0F8AB'; // '#EDFCF2'; //'#66FF66'; // '#EDFCF2';
          break;
        case 'yellow':
          color = '#FEEE95'; // '#FEFDF0'; // '#FFFF66'; // '#FEFDF0';
          break;
        case 'red':
          color = '#FECDCA'; // '#FEF3F2'; // '#FF6666'; // '#FEF3F2';
          break;
        default:
          break;
      }
    }

    // UtilsService.consoledev(props);
    return (
      <rect x={props.cx} y={props.cy} width={10} height={props.height*8} opacity={props.opacity} fill={color} />
    )
  };

  // ScatterChart:  fill="#808080"
  //UtilsService.consoledev('labels', labels);

  // Construct a tooltip for each row that needs one
  //for (const charge of chargeMatches) {
  //  <a data-tooltip-id=
  //}

    //if (charge.ppc_negotiated_charge > 0) {
    //  labels.push({name: charge.ppc_payer, value: charge.ppc_negotiated_charge, unit: ''});
    //}
  //}

  // Build the grid
  const columns = [
    { key: 'ppc_payer', name: 'Payer', resizeable: true, frozen: true, sortable: true},
    { key: 'ppc_plan', name: 'Plan', resizeable: true, frozen: true, sortable: true},
    { key: 'charge_setting', name: 'Setting', frozen: true, resizeable: true},
    { key: 'charge_billing_class', name: 'Billing Class', frozen: true, resizeable: true},
    { key: 'item_modifier', name: 'Modifier', frozen: true, resizeable: true},
    { key: 'ppc_negotiated_charge', name: 'Charge', resizeable: true, frozen: true, sortable: true},
    { key: 'info', name: 'Info', width: 20},
  ];

  const rows = chargeMatches.map((charge: IChargeInterface) => {
    return {
      id: charge.charge_id,
      ppc_payer: charge.ppc_payer,
      ppc_plan: charge.ppc_plan,
      charge_setting: charge.charge_setting,
      charge_billing_class: charge.charge_billing_class,
      item_modifier: charge.item_modifier,
      ppc_negotiated_charge: charge.ppc_negotiated_charge,
      info: '', // <Info key={'i'+charge.charge_id} charge={charge}/>
      color: ''
    }
  });

  // Format x-axis to include a '$'
  const XAxisFormatter = (amount: any) => {
    // Convert to currency and then remove any decimal places
    let value = UtilsService.formatCurrency(amount?.toString());
    if (value?.includes('.')) {
      value = value.substring(0, value.indexOf('.'));
    }
    return value;
  }

  return (
    <section className="section-search-results">
    <div className="container-medium">
      <div className="padding-small">
        <div className="search-results-wrapper">
          <div className="section-divider-line"></div>
          { /*}
          <div className="number-of-results-wrapper">
            <b>{chargeMatches.length} Charge Results</b>
            { errorstring &&
              <span style={{marginLeft: 0}} className="results-label">.&nbsp;{errorstring}</span>
            }
          </div>
          */}

          { datapoints.length > 0 && showgraph &&
          <ResponsiveContainer width={'100%'} height={122}>
            <ScatterChart margin={{ top: 10, right: 10, bottom: 10, left: 10 }} style={{backgroundColor: '#f9f9f9'}} >
              <XAxis type="number" dataKey="x" name="charge" unit="" tickFormatter={XAxisFormatter} />
              <YAxis tick={false} type="number" domain={[0,7]} dataKey="y" name="weight" unit="" />
              <Tooltip content={<CustomTooltip />} payload={labels} cursor={{ strokeDasharray: '3 3' }} />
              <Scatter name="" data={datapoints} fill="#bbc5cf" shape={<CustomBar opacity="0.8" fill="#0059A7" />} />
            </ScatterChart>
          </ResponsiveContainer>  
          }

<div className="grid-container">
<ChargesDataGrid />
</div>


{ /*
    <table><thead><tr><th>Payer</th><th>Plan</th><th>Setting</th><th>Billing Class</th><th>Charge</th><th></th></tr></thead><tbody>



    <>
      {chargeMatches.map((charge: IChargeInterface) => (
        <Charge key={charge.charge_id} charge={charge} />
      ))}
    </>
    </tbody></table>
*/ }
{ /*
    <>
    <ReactTooltip key={'ttInfo'} id={'ttInfo'} />
    </>
*/ }
        </div>
      </div>
    </div>
    </section>

  );
};

export default Charges;