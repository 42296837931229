// FacilityDetails.tsx

import * as React from 'react';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import { Link, useNavigate } from 'react-router-dom';

import { Header } from '../../branding/Header';
import { Footer } from '../../branding/Footer';
import { ShowFile } from '../../file/ShowFile/ShowFile';

import { ISearchInterface, IMatchInterface, IFacilityInterface } from '../../../@types/context';
import { SearchContext } from '../../context/SearchContext';
import { MatchContext } from '../../context/MatchContext';

import Facility from '../Facility/Facility';

import MissingPage from '../../../routes/missingpage';

import AnalyticsService from '../../../services/analytics.service';
import ServerService from '../../../services/server.service';

const analyticsService = new AnalyticsService();
const serverService = new ServerService();


// import '../../../styles.css'

type Props = {
  facilityid: string;
};

const FacilityDetails: React.FC<Props> = ({ facilityid }) => {
  const { facilitySearch, descriptionSearch, codeSearch } = React.useContext(SearchContext) as ISearchInterface;
  const { matches, stateview, welcome, saveMatches, saveView } = React.useContext(MatchContext) as IMatchInterface;
 
  const navigate = useNavigate();

 //UtilsService.consoledev('matches', facilityid, matches);

  let facility: IFacilityInterface | undefined = matches.find(x => x.id == facilityid);
  let isretrieving = false;
  let directlink = false;

  React.useEffect(() => {
    if (!facility) {
      // Attempt to retrieve it from the server. isretrieving should eliminate extra web requests
      if (!isretrieving) {
        try {
          isretrieving = true;
          //UtilsService.consoledev('Retrieving for', facilityid);
          serverService.getResponse(`facility/id/${facilityid}`).then(data => {
            if (!('matches' in data)) {
              data['matches'] = [];
            }
            const matches: IFacilityInterface[] = data['matches'];
            //PBR saveMatches('', '', matches, data['error']);
            //UtilsService.consoledev('Retrieved', data);
    
            facility = matches.find(x => x.id == facilityid);
            if (!facility) {
              //UtilsService.consoledev('Navigating to home page', facility);
              isretrieving = false;
              navigate('/');
            }
            else {
              directlink = true;
              // turn off welcome page
              if (welcome) {
                //UtilsService.consoledev('Setting list view');
                isretrieving = false;
                saveView(stateview, true, false, false);
              }
            }
          })
        }
        catch(ex) {
          isretrieving = false;
        }
      }

      // UtilsService.consoledev('data', data);
      //if (!('matches' in data)) {
      //  data['matches'] = [];
      //}
      //saveMatches(data['matches'], data['error']);      
    }
    else {
      // Record this event
      const title = `details for ${facility.name}, ${facility.city}, ${facility.state}`;
      analyticsService.pageView(title, window.location.href);
      //analyticsService.pageTracking('details', 'details', {id: facilityid, name: facility.name, city: facility.city, state: facility.state});
    }

  });

  if (!facility) {
    return null;
  }

  // UtilsService.consoledev('Showing facility', facility);
  return (
    <div>
    <section className="section-details-hospital-info">
    <div className="container-small center-details-page">
      <div className="padding-small">
        <div className="details-hospital-info-wrapper">
          <a href="#" onClick={() => navigate('/')} className="back-to-search-link-block w-inline-block"><img src='/images/ICON_back.svg' loading="lazy" alt="back button" className="icon_back"/>
            <div className="label-back-to-search">search</div>
          </a>
          <div className="name-hospital-seach-result details-hospital-name">{facility?.name}</div>
          <a href={facility.url} target="_blank" className="link-block-5 w-inline-block">
            <div className="hospital-url">{facility?.url}</div>
          </a>
          <div className="details-hospital-wrapper">
            <div id="w-node-_114a0f28-4e1e-1765-5c50-b6d979b05d96-8d4be3ed" className="details-address-phone-ccn-wrapper">
              <div className="grid-hospital-details">
              <Link id="w-node-b46f49af-6191-255e-db1e-8cdb0ea4a8b9-8d4be3ed" className="link-block-4 w-inline-block" to={'/map/' + facility.id}>
                  <div className="map-pin-blue-icon-wrapper"><img src={process.env.PUBLIC_URL + '/images/ICON_pin_blue.svg'} loading="lazy" id="w-node-db507dec-5110-0625-995f-c519fbfc8ad9-8d4be3ed" alt="map view icon" className="details-address-icon-link"/></div>
                  <div id="w-node-_4e8266c1-6898-cec1-579e-b783d40089d2-8d4be3ed" className="details-hospital-street-address">{facility?.address}</div>
                </Link>
                <a id="w-node-a3eac0d5-a762-3b16-4265-45a9441bbf19-8d4be3ed" href="#" className="details-address-link-wrapper w-inline-block">
                  <div className="details-hospital-city-state-zip">{facility?.city},&nbsp;{facility?.state}&nbsp;{facility?.zip}</div>
                </a>
                <a id="w-node-b21336b7-8f6f-1864-bc30-4228286cdd1d-8d4be3ed" href="#" className="details-hospital-phone-number-wrapper w-inline-block">
                  <div className="phone-icon-blue-wrapper"><img src={process.env.PUBLIC_URL + '/images/ICON_phone.svg'} loading="lazy" alt="Phone number icon" className="details-phone-icon-link mobile-icon-invoke-call"/></div>
                  <div className="details-hospital-phone-number">{facility?.phone}</div>
                </a>
                <div id="w-node-_7dd16fd3-3280-13fe-c21d-f4250d73c833-8d4be3ed" className="details-hospital-ccn-wrapper"><img src={process.env.PUBLIC_URL + '/images/ICON_pin_blue.svg'} loading="lazy" id="w-node-a81c3d76-b684-dc1d-ae2b-b62981d5e81d-8d4be3ed" alt="map view icon" className="details-address-icon-link hide-map-pin-blue"/>
                { facility?.ccn &&
                  <div className="details-hospital-ccn-label">CCN</div>
                }
                { facility?.ccn &&
                  <div className="details-hospital-ccn-number">{facility?.ccn}</div>
                }
                </div>
              </div>
            </div>
            <div id="w-node-_31d4f197-fc05-af3b-883d-723a5b83e473-8d4be3ed" className="details-map-thumbnail-wrapper w-inline-block">
            <Link id="w-node-_31d4f197-fc05-af3b-883d-723a5b83e473-8d4be3ed" className="details-map-thumbnail-wrapper w-inline-block" to={'/map/' + facility.id}>

            <MapContainer style={{width: '174px', height: '95px'}} scrollWheelZoom={false} zoomControl={false} dragging={false} boxZoom={false} touchZoom={false} center={[parseFloat(facility?.lat || '28'), parseFloat(facility?.long || '-82')]} zoom={13}>
          <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright%22%3EOpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
          <Marker position={[parseFloat(facility?.lat || '28'), parseFloat(facility?.long || '-82')]}>
          </Marker>
        </MapContainer>
        </Link>
        </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section className="section-details-report-issue-form">
    <div className="container-small center-details-page">
      <div className="padding-xsmall">
        <div className="details-report-issue-form-wrapper">
          <a href="https://patientrightsadvocate.atlassian.net/servicedesk/customer/portal/1" target="_blank" className="button-report-issue-form w-button">report an issue</a>
        </div>
      </div>
    </div>
  </section>
  </div>
  );
};


export default FacilityDetails;