// analytics.service.ts

import UtilsService from "./utils.service";

// Google Analytics identifier. Production and staging have different values//

//const googleAnalytics = 'G-67MVLCFWC9'; // Production (CO)
const googleAnalytics = 'G-8NNT3CFX2S'; // Production (NYC)
//const googleAnalytics = 'G-0LSLSZGQGG'; // Staging 

// In addition to making this true, you also need to modify the analytics code in index.html (search for gtag)
const ANALYTICSENABLED=true;


export default class AnalyticsService
{
  public getGoogleId() : string {
    return googleAnalytics;
  }

  /**
   * Generate a page_view event
   * @param pageTitle 
   * @param pageLocation 
   */
  public pageView(pageTitle: string, pageLocation: string)
  {
    if (ANALYTICSENABLED) {
      (window as any).gtag('event', 'page_view', {
        page_title: pageTitle,
        page_location: pageLocation
      });
    }
    else {
      UtilsService.consoledev('AnalyticsService.pageView disabled', pageTitle, pageLocation);
    }
  }


  /**
   * Write an event
   * @param path {string} Path to write to Analytics (ie. location.pathname + location.search + location.hash)
   * @param search {string} Search component (ie. query parameters to show). Default = ''
   * @param hash {string} Any hash information. Default = ''
   */
  public pageTracking(eventName: string, path: string, parameters: any = '', hash: string = '')
  {
    (window as any).gtag("event", eventName, parameters);
/*
    {
      path: path,     // location.pathname + location.search + location.hash,
      page_search: search, // location.search,
      // page_hash: hash,     // location.hash,
    });
*/
  }

}
