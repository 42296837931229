// root.tsx (Used to be App.tsx)

import React, {useEffect} from 'react';
import { Routes, Route, Outlet, Link } from "react-router-dom";
import '../styles.css';

import FacilitySearch from '../components/facility/FacilitySearch/FacilitySearch';
import ReportIssue from '../components/feedback/ReportIssue/ReportIssue';
import FacilitiesOnly from '../components/facility/FacilitiesOnly/FacilitiesOnly';
import MapviewOnly from '../components/MapviewOnly/MapviewOnly';
import Session from '../components/user/Session/Session';
import ServerService from '../services/server.service';

import { ISearchInterface, IMatchInterface, IFacilityInterface } from '../@types/context';
import { SearchContext } from '../components/context/SearchContext';
import { MatchContext } from '../components/context/MatchContext';

import { Header } from '../components/branding/Header';
import { Welcome } from '../components/branding/Welcome';
import { Footer } from '../components/branding/Footer';
import Stateview from '../components/Stateview/Stateview';
import Items from '../components/item/Items/Items';
import Charges from '../components/charge/Charges/Charges';

import ConstantService from '../services/constant.service';
// const constantService = new ConstantService();

export default function Root() {

  const { sessionid } = React.useContext(SearchContext) as ISearchInterface;
  const { welcome, stateview, saveView } = React.useContext(MatchContext) as IMatchInterface;

  useEffect(() => {
    const findServer = async () => {
      const serverService = new ServerService();
      const resp = await serverService.getResponse('check');
      // UtilsService.consoledev('Root check', resp);
    };

    // Ping the server to get a sessionid and requestid, but only if we do not have one
    if (sessionid && sessionid.length > 0) {
      // Do nothing
    }
    else {
      findServer();
    }
  }, []);

  return (
    <div>
      <div className="page-wrapper">
        {/*
        <div className="global-styles w-embed">
          <link href="/css/globalstyles.css" rel="stylesheet" type="text/css"/>
        </div>
        */}

        <Header/>
        <main className="main-wrapper">
          <div>
            <Session/>
            <Welcome/>
            { !welcome && 
              <div>
                <FacilitySearch tableid="123" />
                <ReportIssue />
              </div>
            }

          </div>
        </main>
        <Footer/>
      </div>

    </div>
  );
}