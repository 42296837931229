// ReportIssue.tsx

import * as React from 'react';
import { useAsync, useAsyncCallback } from "react-async-hook";
import { Link } from 'react-router-dom';
import { IFacilityInterface, IItemInterface, IMatchInterface } from '../../../@types/context';
import { MatchContext } from '../../context/MatchContext';
import ServerService from '../../../services/server.service';

import UtilsService from "../../../services/utils.service";

import { ShowFile } from '../../file/ShowFile/ShowFile';

type Props = {
};

const ReportIssue: React.FC<Props> = () => {
  return (
    <section className="section-details-report-issue-form">
    <div className="container-small center-details-page">
      <div className="padding-xsmall">
        <div className="details-report-issue-form-wrapper">
          <a href="https://patientrightsadvocate.atlassian.net/servicedesk/customer/portal/1/group/-1" target="_blank" className="button-report-issue-form w-button">report an issue</a>
        </div>
      </div>
    </div>
  </section>
  /*
    <div className="container">
      <div style={{paddingRight: 30, marginRight: 29, float: 'right'}}>
        <Link to="https://patientrightsadvocate.atlassian.net/servicedesk/customer/portal/1/group/-1">
          Report an Issue
        </Link>
      </div>
    </div>
    */
  );
};

export default ReportIssue;