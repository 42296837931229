// MapContext.js
import React, { createContext, useState, FC, ReactNode } from 'react';

import { ISearchInterface, IMapInterface } from '../../@types/context';

import ConstantService from '../../services/constant.service';
import UtilsService from "../../services/utils.service";

function delay(ms: number) {
  return new Promise( resolve => setTimeout(resolve, ms) );
}

// nop versions of setting the context
function default0(lat: number, lng: number, zoom: number, latsum: number, bounds: any) {};
function default1(zoom: number) {};

// Default map center is CO
const defaultlat = 39.395;
const defaultlong = -105.2276;
const defaultzoom = 12;

const sv = ConstantService.getStateView();
const defaultMapContext = {
  centerlat: defaultlat,
  centerlong: defaultlong,
  zoom: defaultzoom,
  iconzoom: 6,
  latsum: 0,
  bounds: null,
  setZoom: default1,
  saveMap: default0,
};


interface Props {
  children: ReactNode;
}

export const MapContext = createContext<IMapInterface>(defaultMapContext);

//const AppProvider: React.FC<React.ReactNode> = ({ children: {children: ReactNode} }) => {
const AppSearchProvider: React.FC<Props> = ({ children }: Props) => {
  const [context, setContext] = React.useState<IMapInterface>();

  //React.useEffect(() => {
  //  //UtilsService.consoledev('useEffect context', context);
  //})

  const saveMap = async (centerlat: number, centerlng: number, zoom: number, latsum: number, bounds: any) => {
    UtilsService.consoledev('*****saveMap', centerlat, centerlng, zoom, latsum, bounds);
    const newcontext: IMapInterface = {
      centerlat,
      centerlong: centerlng,
      zoom: zoom,
      iconzoom: zoom,
      latsum,
      bounds,
      setZoom: context ? context.setZoom: default1,
      saveMap: context ? context.saveMap: default0,
    };
   
    setContext(newcontext);
  }

  const setZoom = async (zoom: number) => {
    const newcontext: IMapInterface = {
      centerlat: context ? context.centerlat : defaultlat,
      centerlong: context ? context.centerlong : defaultlong,
      zoom,
      iconzoom: zoom,
      latsum: context ? context.latsum : 0,
      bounds: context ? context.bounds : null,
      setZoom: context ? context.setZoom: default1,
      saveMap: context ? context.saveMap: default0,
    };
   
    setContext(newcontext);    
  }

  const centerlat = context ? context.centerlat : defaultlat;
  const centerlong = context ? context.centerlong : defaultlong;
  const zoom = context ? context.zoom : defaultzoom;
  const iconzoom = context ? context.iconzoom : 6;
  const latsum = context ? context.latsum : 0;
  const bounds = context ? context.bounds : null;

  return <MapContext.Provider value={{ centerlat, centerlong, zoom, iconzoom, latsum, bounds, setZoom, saveMap}}>{children}</MapContext.Provider>;
};  
  
export default AppSearchProvider;
