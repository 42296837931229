// Footer.tsx
// This is no longer used. See index.html in public
export const Footer = () => {
  return (
    <section className="section-footer">
      <div className="footer-wrapper">
        <div className="footer-content">
          <div className="footer-layout">
            <a id="w-node-_449f8d46-caa4-d9c4-0ad0-045a0afe9823-b725730c" href="https://www.patientrightsadvocate.org/home" className="logo-mobile w-inline-block"><img loading="lazy" src="images/PRAlogofooter.png" alt="Patient Rights Advocate" className="image logo-footer-white"/></a>
            <a id="w-node-_449f8d46-caa4-d9c4-0ad0-045a0afe9825-b725730c" href="https://www.patientrightsadvocate.org/our-mission" className="footer-link-block mobile-footer w-inline-block">
              <div className="footer-link">OUR MISSION</div>
            </a>
            <a id="w-node-_449f8d46-caa4-d9c4-0ad0-045a0afe982b-b725730c" href="https://www.patientrightsadvocate.org/termsofservice" className="footer-link-block w-inline-block">
              <div className="footer-link">TERMS OF SERVICE</div>
            </a>
            <a id="w-node-_449f8d46-caa4-d9c4-0ad0-045a0afe9831-b725730c" href="https://www.patientrightsadvocate.org/contact" className="footer-link-block w-inline-block">
              <div className="footer-link">CONTACT US</div>
            </a>
            <div id="w-node-_449f8d46-caa4-d9c4-0ad0-045a0afe9834-b725730c" className="footer-social-media-icons-wrapper">
              <a href="https://twitter.com/PtRightsAdvoc" target="_blank" className="footer-social-media-icon-link-block w-inline-block"><img width="20" height="20" alt="Twitter" src="images/twitter_white.svg" loading="lazy"/></a>
              <a href="https://www.facebook.com/thepatientrightsadvocate" target="_blank" className="footer-social-media-icon-link-block w-inline-block"><img width="20" height="20" alt="Facebook" src="images/facebook_white.svg" loading="lazy"/></a>
              <a href="https://www.instagram.com/patientrightsadvocate/" target="_blank" className="footer-social-media-icon-link-block w-inline-block"><img width="20" height="20" alt="Instagram" src="images/instagram_white.svg" loading="lazy"/></a>
              <a href="https://www.youtube.com/channel/UCN7j6idxar-akDLDzVWD46Q" target="_blank" className="footer-social-media-icon-link-block w-inline-block"><img width="20" height="20" alt="Youtube" src="images/youtube_white.svg" loading="lazy"/></a>
            </div>
            <div id="w-node-_449f8d46-caa4-d9c4-0ad0-045a0afe983d-b725730c" className="btn-footer-subscribe-for-updates-wrapper">
              <a href="https://visitor.r20.constantcontact.com/manage/optin?v=001SK-nF_yGPO2OJk5ykixhSDkZudU5GvXJsdzCno5F1v4um0_iPJrvX09x7cb1a23yn_GHYJ4iTkMIffA1l4O5YSOO-Ie7Io5BOFsdK2sB4bP-zl85wTv-4FiKjDGW_p6aThzQDt5wDXgq6xlT0PVsOo9FZxqe0yIX4zJKRiiYYRpnGlMDijWepw%3D%3D" className="button-2 mobile-subscribe-btn w-button">SUBSCRIBE FOR UPDATES</a>
            </div>
          </div>
          <div className="footer-copyright">
            <div className="copyright-text">© Copyright Patient Rights Advocate 2024. All rights reserved.<br/>CPT® copyright 2020 American Medical Association. All Rights Reserved.</div>
          </div>
        </div>
      </div>
    </section>
  );
};



