// Header.tsx

export const Header = () => {
  return (
    <div className="header-announcement-bar-wrapper">
      <div className="header-display-desktop">
        <div data-animation="default" data-collapse="none" data-duration="300" data-easing="ease" data-easing2="ease" role="banner" className="header-title-nav-wrapper mobile-nav w-nav">
          <a href="https://www.patientrightsadvocate.org/" className="header-title w-nav-brand">
            <img src="/images/PRALogo_NEWCOLOR_source.png" loading="lazy" height="114" alt="PRA icon" sizes="(max-width: 479px) 78vw, (max-width: 767px) 200.53125px, 457.21875px" srcSet="/images/PRALogo_NEWCOLOR-p-500.png 500w, /images/PRALogo_NEWCOLOR-p-800.png 800w, /images/PRALogo_NEWCOLOR-p-1080.png 1080w, /images/PRALogo_NEWCOLOR_source.png 1500w" className="logo-mobile"/></a>
          <div className="header-nav mobile-nav">
            <nav role="navigation" className="header-nav-wrapper w-nav-menu">
              <a href="https://www.patientrightsadvocate.org/" className="header-nav-item-header-nav-item--collection w-nav-link">HOME</a>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};



