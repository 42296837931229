// SearchContext.js
import React, { createContext, useState, FC, ReactNode } from 'react';

import { IFacilityInterface, ISearchInterface } from '../../@types/context';

import ConstantService from '../../services/constant.service';
import UtilsService from "../../services/utils.service";

function delay(ms: number) {
  return new Promise( resolve => setTimeout(resolve, ms) );
}

// nop versions of setting the context
function default0(session: string) {};
function default1(facilitysearch?: string, descriptionsearch?: string, codesearch?: string) {};
function default2(matches: IFacilityInterface[], error?: string) {};
function default3(listview: boolean, mapview: boolean, welcome: boolean) {};

const sv = ConstantService.getStateView();
const defaultSearchContext = {
  sessionid: '',
  facilitySearch: '',
  descriptionSearch: '',
  codeSearch: '',
  facilityid: '',
  saveSession: default0,
  saveSearch: default1,
  saveFacilityId: default0,
};


interface Props {
  children: ReactNode;
}

export const SearchContext = createContext<ISearchInterface>(defaultSearchContext);

//const AppProvider: React.FC<React.ReactNode> = ({ children: {children: ReactNode} }) => {
const AppSearchProvider: React.FC<Props> = ({ children }: Props) => {
  const [context, setContext] = React.useState<ISearchInterface>();

  //React.useEffect(() => {
  //  //UtilsService.consoledev('useEffect context', context);
  //})

  const saveSearch = async (newfacilitysearch?: string, newdescriptionsearch?: string, newcodesearch?: string) => {
    //UtilsService.consoledev('saveSearch', newsearch, newsearchState);
    // UtilsService.consoledev('saveSearch', search, context);
    // UtilsService.consoledev('saveSearch', search, searchState);
    const newcontext: ISearchInterface = {
      sessionid: context ? context.sessionid : '',
      facilitySearch: (newfacilitysearch == null) ? '' : newfacilitysearch,
      descriptionSearch: (newdescriptionsearch == null) ? '' : newdescriptionsearch,
      codeSearch: (newcodesearch == null) ? '' : newcodesearch,
      facilityid: context ? context.facilityid : '',
      saveSession: context ? context.saveSession: default0,
      saveSearch: context ? context.saveSearch : default1,
      saveFacilityId: context ? context.saveFacilityId : default0,
    };

    
    setContext(newcontext);
    //await delay(10);
    //UtilsService.consoledev('new context', newcontext, context);
  }

  const saveSession = (sessionid: string) => {
    //UtilsService.consoledev(`saveSession: ${context?.searchState}`);
    const newcontext: ISearchInterface = {
      sessionid,
      facilitySearch: context ? context.facilitySearch : '',
      descriptionSearch: context ? context.descriptionSearch : '',
      codeSearch: context ? context.codeSearch : '',
      facilityid: context ? context.facilityid : '',
      saveSession: context ? context.saveSession: default0,
      saveSearch: context ? context.saveSearch : default1,
      saveFacilityId: context ? context.saveFacilityId : default0,
    };
    setContext(newcontext);
  }

  const saveFacilityId = (facilityid: string) => {
    UtilsService.consoledev(`saveFacilityId: ${facilityid}`);
    const newcontext: ISearchInterface = {
      sessionid: context ? context.sessionid : '',
      facilitySearch: context ? context.facilitySearch : '',
      descriptionSearch: context ? context.descriptionSearch : '',
      codeSearch: context ? context.codeSearch : '',
      facilityid,
      saveSession: context ? context.saveSession: default0,
      saveSearch: context ? context.saveSearch : default1,
      saveFacilityId: context ? context.saveFacilityId : default0,
    };
    setContext(newcontext);
  }

  const sessionid = context ? context.sessionid : '';
  const facilitySearch = context ? context.facilitySearch : '';
  const descriptionSearch = context ? context.descriptionSearch : '';
  const codeSearch = context ? context.codeSearch : '';
  const facilityid = context ? context.facilityid : '';
  const sv = ConstantService.getStateView();

  return <SearchContext.Provider value={{ sessionid, facilitySearch, descriptionSearch, codeSearch, facilityid, saveSession, saveSearch, saveFacilityId }}>{children}</SearchContext.Provider>;
};  
  
export default AppSearchProvider;
