// MatchContext.js
import React, { createContext, useState, FC, ReactNode, useMemo } from 'react';

import { IFacilityInterface, IItemInterface, IChargeInterface, IMatchInterface } from '../../@types/context';

import UtilsService from "../../services/utils.service";
import ConstantService from '../../services/constant.service';

import { v4 as uuidv4 } from 'uuid';
import { isNumericLiteral } from 'typescript';

function delay(ms: number) {
  return new Promise( resolve => setTimeout(resolve, ms) );
}

// nop versions of setting the context
function default0(session: string) {};
function default0a(state: boolean) {};
function default0b() {};
function default1(search?: string, searchState?: string) {};
function default4(matchFacilitySearch: string, matchDescriptionSearch: string, matchCodeSearch: string, matches: IFacilityInterface[], itemMatches: IItemInterface[], chargeMatches: IChargeInterface[], error?: string) {};
function default4a(chargeMatches: IChargeInterface[], error?: string) {};
function default4a1(charge_min: string, charge_max: string, charge_gross: string, charge_cash: string) {}
function default4a2(chargeHeaders: string, chargeData: string, charge_min: string, charge_max: string, charge_gross: string, charge_cash: string, hasSetting: boolean, hasBillingClass: boolean, hasModifiers: boolean, hasNotes: boolean) {}
function default4b(facilityMatches: IFacilityInterface[], error?: string) {};
function default4c(itemMatches: IItemInterface[], error?: string) {};
function default3(stateview: string | undefined, listview: boolean, mapview: boolean, welcome: boolean) {};
function default5(facility?: IFacilityInterface) {};
function default6(item?: IItemInterface) {};
function default7(facilitySearch?: string, descriptionSearch?: string, codeSearch?: string) {};

const defaultMatchContext = {
  matchState: ConstantService.negotiated_charge_region,
  matches: [],
  filteredMatches: [],
  recentMatches: [],
  facilityFilter: '',
  itemMatches: [],
  chargeMatches: [],
  itemState: '',
  showItems: false,
  chargeHeaders: '',
  chargeData: '',
  hasSetting: true,
  hasBillingClass: true,
  hasModifiers: true,
  hasNotes: true,
  charge_min: '',
  charge_max: '',
  charge_gross: '',
  charge_cash: '',
  chargeState: '',
  matchFacilitySearch: '',
  matchDescriptionSearch: '',
  matchCodeSearch: '',
  filter_setting: 'ip,op',
  filter_billingclass: 'facility,professional',
  filter_modifiers: 'modifier',
  //facilityid: '',
  showgraph: true,
  error: '',
  listview: true, //PBR Was originally false
  mapview: false,
  welcome: true,
  welcomeState: '',
  saveWelcomeState: default0,
  saveMatches: default4,
  saveChargeMatches: default4a,
  saveCharges: default4a1,
  saveCsvCharges: default4a2,
  saveFacilityMatches: default4b,
  saveRecentMatches: default4b,
  saveFacilityFilter: default0,
  clearFacility: default0b,
  clearItem: default0b,
  saveItemMatches: default4c,
  saveView: default3,
  saveSelectedFacility: default5,
  saveSelectedItem: default6,
  saveItemState: default0,
  saveShowItems: default0a,
  saveChargeState: default0,
  saveSearchParameters: default7,
  saveFacilitySearch: default0,
  saveDescriptionSearch: default0,
  saveCodeSearch: default0,
  saveFilterSetting: default0,
  saveFilterBillingClass: default0,
  saveFilterModifiers: default0,
  saveShowGraph: default0a
};

interface Props {
  children: ReactNode;
}

export const MatchContext = createContext<IMatchInterface>(defaultMatchContext);

//const AppProvider: React.FC<React.ReactNode> = ({ children: {children: ReactNode} }) => {
const AppMatchProvider: React.FC<Props> = ({ children }: Props) => {
  const [context, setContext] = React.useState<IMatchInterface>();

  //React.useEffect(() => {
  //  //UtilsService.consoledev('useEffect context', context);
  //})

  /**
   * Duplicate the context for changing
   * @returns 
   */
  const copyContext = (): IMatchInterface => {
    const newcontext: IMatchInterface = {
      stateview: context ? context.stateview : undefined,
      listview: context ? context.listview : false,
      mapview: context ? context.mapview : false,
      welcome: context ? context.welcome : true,
      welcomeState: context ? context.welcomeState : '',
      matchState: context ? context.matchState : ConstantService.negotiated_charge_region,
      matches: context ? context.matches : [],
      filteredMatches: context ? context.filteredMatches : [],
      recentMatches: context ? context.recentMatches : [],
      facilityFilter: context ? context.facilityFilter : '',
      itemMatches: context ? context.itemMatches : [],
      chargeMatches: context ? context.chargeMatches : [],
      itemState: context ? context.itemState : '',
      showItems: context ? context.showItems : false,
      chargeHeaders: context ? context.chargeHeaders : '',
      chargeData: context ? context.chargeData : '',
      hasSetting: context ? context.hasSetting : true,
      hasBillingClass: context ? context.hasBillingClass : true,
      hasModifiers: context ? context.hasModifiers : true,
      hasNotes: context ? context.hasNotes : true,
      charge_min: context ? context.charge_min : '',
      charge_max: context ? context.charge_max : '',
      charge_gross: context ? context.charge_gross : '',
      charge_cash: context ? context.charge_cash : '',
      chargeState: context ? context.chargeState : '',
      selectedFacility: context ? context.selectedFacility : undefined,
      selectedItem: context ? context.selectedItem : undefined,
      matchFacilitySearch: context ? context.matchFacilitySearch : '',
      matchDescriptionSearch: context ? context.matchDescriptionSearch : '',
      matchCodeSearch: context ? context.matchCodeSearch : '',
      filter_setting: context ? context.filter_setting : 'ip,op',
      filter_billingclass: context ? context.filter_billingclass : 'facility,professional',
      filter_modifiers: context ? context.filter_modifiers : 'modifier',
      //facilityid: context ? context.facilityid : '',
      showgraph: context ? context.showgraph : true,
      error: context ? context.error : '',
      saveWelcomeState: context ? context.saveWelcomeState : default0,
      saveMatches: context ? context.saveMatches : default4,
      saveChargeMatches: context ? context.saveChargeMatches : default4a,
      saveCharges: context ? context.saveCharges : default4a1,
      saveCsvCharges: context ? context.saveCsvCharges : default4a2,
      saveFacilityMatches: context ? context.saveFacilityMatches : default4b,
      saveRecentMatches: context ? context.saveRecentMatches : default4b,
      saveFacilityFilter: context ? context.saveFacilityFilter : default0,
      clearFacility: context ? context.clearFacility : default0b,
      clearItem: context ? context.clearItem : default0b,
      saveItemMatches: context ? context.saveItemMatches : default4c,
      saveView: context ? context.saveView : default3,
      saveSelectedFacility: context ? context.saveSelectedFacility : default5,
      saveSelectedItem: context ? context.saveSelectedItem : default6,
      saveChargeState: context ? context.saveChargeState : default0,
      saveShowItems: context ? context.saveShowItems : default0a,
      saveItemState: context ? context.saveItemState : default0,
      saveSearchParameters: context ? context.saveChargeState : default7,
      saveFacilitySearch: context ? context.saveFacilitySearch : default0,
      saveDescriptionSearch: context ? context.saveDescriptionSearch : default0,
      saveCodeSearch: context ? context.saveCodeSearch : default0,
      saveFilterSetting: context ? context.saveFilterSetting: default0,
      saveFilterBillingClass: context ? context.saveFilterBillingClass: default0,
      saveFilterModifiers: context ? context.saveFilterModifiers: default0,
      saveShowGraph: context ? context.saveShowGraph: default0a
    };

    return newcontext;
  }

  const saveWelcomeState = async (welcomeState: string) => {
    //UtilsService.consoledev('saveWelcomeState', welcomeState);
    const newcontext: IMatchInterface = {
      stateview: context ? context.stateview : undefined,
      listview: context ? context.listview : false,
      mapview: context ? context.mapview : false,
      welcome: context ? context.welcome : false, // true,
      welcomeState,
      matchFacilitySearch: context ? context.matchFacilitySearch : '',
      matchDescriptionSearch: context ? context.matchDescriptionSearch : '',
      matchCodeSearch: context ? context.matchCodeSearch : '',
      filter_setting: context ? context.filter_setting : 'ip,op',
      filter_billingclass: context ? context.filter_billingclass : 'facility,professional',
      filter_modifiers: context ? context.filter_modifiers : 'modifier',
      //facilityid: context ? context.facilityid : '',
      matchState: context ? context.matchState : ConstantService.negotiated_charge_region,
      matches: context ? context.matches : [],
      filteredMatches: context ? context.filteredMatches : [],
      recentMatches: context ? context.recentMatches : [],
      facilityFilter: context ? context.facilityFilter : '',
      itemMatches: context ? context.itemMatches : [],
      chargeMatches: context ? context.chargeMatches : [],
      itemState: context ? context.itemState : '',
      showItems: context ? context.showItems : false,
      chargeHeaders: context ? context.chargeHeaders : '',
      chargeData: context ? context.chargeData : '',
      hasSetting: context ? context.hasSetting : true,
      hasBillingClass: context ? context.hasBillingClass : true,
      hasModifiers: context ? context.hasModifiers : true,
      hasNotes: context ? context.hasNotes : true,
      charge_min: context ? context.charge_min : '',
      charge_max: context ? context.charge_max : '',
      charge_gross: context ? context.charge_gross : '',
      charge_cash: context ? context.charge_cash : '',
      chargeState: context ? context.chargeState : '',
      selectedFacility: context ? context.selectedFacility : undefined,
      selectedItem: context ? context.selectedItem : undefined,
      error: context ? context.error : '',
      showgraph: context ? context.showgraph : true,
      saveWelcomeState: context ? context.saveWelcomeState : default0,
      saveMatches: context ? context.saveMatches : default4,
      saveChargeMatches: context ? context.saveChargeMatches : default4a,
      saveCharges: context ? context.saveCharges : default4a1,
      saveCsvCharges: context ? context.saveCsvCharges : default4a2,
      saveFacilityMatches: context ? context.saveFacilityMatches : default4b,
      saveRecentMatches: context ? context.saveRecentMatches : default4b,
      saveFacilityFilter: context ? context.saveFacilityFilter : default0,
      clearFacility: context ? context.clearFacility : default0b,
      clearItem: context ? context.clearItem : default0b,
      saveItemMatches: context ? context.saveItemMatches : default4c,
      saveView: context ? context.saveView : default3,
      saveSelectedFacility: context ? context.saveSelectedFacility : default5,
      saveSelectedItem: context ? context.saveSelectedItem : default6,
      saveChargeState: context ? context.saveChargeState : default0,
      saveShowItems: context ? context.saveShowItems : default0a,
      saveItemState: context ? context.saveItemState : default0,
      saveSearchParameters: context ? context.saveChargeState : default7,
      saveFacilitySearch: context ? context.saveFacilitySearch : default0,
      saveDescriptionSearch: context ? context.saveDescriptionSearch : default0,
      saveCodeSearch: context ? context.saveCodeSearch : default0,
      saveFilterSetting: context ? context.saveFilterSetting: default0,
      saveFilterBillingClass: context ? context.saveFilterBillingClass: default0,
      saveFilterModifiers: context ? context.saveFilterModifiers: default0,
      saveShowGraph: context ? context.saveShowGraph: default0a
    };
    
    setContext(newcontext);
  }

  const saveFacilitySearch = (matchFacilitySearch: string) => {
    const newcontext = copyContext();
    if (newcontext.matchFacilitySearch !== matchFacilitySearch) {
      newcontext.selectedFacility = undefined;
    }
    newcontext.matchFacilitySearch = matchFacilitySearch;

    newcontext.error = '';

    setContext(newcontext);
  }

  const saveDescriptionSearch = (matchDescriptionSearch: string) => {
    const newcontext = copyContext();
    if (newcontext.matchDescriptionSearch == matchDescriptionSearch) {
      return;
    }

    newcontext.selectedItem = undefined;
    newcontext.itemState = 'pending';
    newcontext.matchDescriptionSearch = matchDescriptionSearch;
    setContext(newcontext);
  }

  const saveCodeSearch = (matchCodeSearch: string) => {
    const newcontext = copyContext();
    if (newcontext.matchCodeSearch == matchCodeSearch) {
      return;
    }

    newcontext.selectedItem = undefined;
    newcontext.itemState = 'pending';
    newcontext.matchCodeSearch = matchCodeSearch;
    setContext(newcontext);
  }

  const saveFilterSetting = (filter_setting: string) => {
    const newcontext = copyContext();
    newcontext.filter_setting = filter_setting;
    setContext(newcontext);
  }

  const saveFilterBillingClass = (filter_billingclass: string) => {
    const newcontext = copyContext();
    newcontext.filter_billingclass = filter_billingclass;
    setContext(newcontext);
  }

  const saveFilterModifiers = (filter_modifiers: string) => {
    const newcontext = copyContext();
    newcontext.filter_modifiers = filter_modifiers;
    setContext(newcontext);
  }


  const saveMatches = (matchFacilitySearch: string, matchDescriptionSearch: string, matchCodeSearch: string, matches: IFacilityInterface[], itemMatches: IItemInterface[], chargeMatches: IChargeInterface[], error?: string) => {
    const newcontext = copyContext();
    UtilsService.consoledev('saveMatches', matches.length);

    newcontext.matches = matches;
    newcontext.itemMatches = itemMatches;

    // Add a charge_id if missing
    for (let i = 0; i < chargeMatches.length; i++) {
      if (!chargeMatches[i].charge_id) {
        chargeMatches[i].charge_id = uuidv4();
      } 
    }
    UtilsService.consoledev(JSON.stringify(chargeMatches, null, 2));

    newcontext.chargeMatches = chargeMatches;
    newcontext.matchFacilitySearch = matchFacilitySearch;
    newcontext.matchDescriptionSearch = matchDescriptionSearch;
    newcontext.matchCodeSearch = matchCodeSearch;

    setContext(newcontext);
  }

  
  const saveSearchParameters = async (newfacilitysearch?: string, newdescriptionsearch?: string, newcodesearch?: string) => {
    const newcontext = copyContext();
    if (newfacilitysearch !== newcontext.matchFacilitySearch) {
      newcontext.matchFacilitySearch = newfacilitysearch ? newfacilitysearch : '';
      newcontext.selectedFacility = undefined;
      newcontext.selectedItem = undefined;
    }
    if (newdescriptionsearch !== newcontext.matchDescriptionSearch || newcodesearch) {
      newcontext.matchDescriptionSearch = newdescriptionsearch ? newdescriptionsearch : '';
      newcontext.matchCodeSearch = newcodesearch ? newcodesearch : '';
      newcontext.selectedItem = undefined;
    }

    setContext(newcontext);
  }

  const saveChargeMatches = (chargeMatches: IChargeInterface[], error?: string) => {
    const newcontext = copyContext();

    // Add a charge_id if missing
    for (let i = 0; i < chargeMatches.length; i++) {
      if (!chargeMatches[i].charge_id) {
        chargeMatches[i].charge_id = uuidv4();
      } 

      // If the negotiated_charge is numeric with more than 2 decimal places, round it to 2 decimal places
      let nc = chargeMatches[i].ppc_negotiated_charge;
      if (nc && nc.includes('.')) {
        let n = parseFloat(nc);
        if (n) {
          nc = n.toFixed(2);
          chargeMatches[i].ppc_negotiated_charge = nc;
        }
      }
    }
    //UtilsService.consoledev(JSON.stringify(chargeMatches, null, 2));

    newcontext.chargeMatches = [...chargeMatches];
    newcontext.chargeState = 'fetched';

    setContext(newcontext);
  }

  const saveCharges = (charge_min: string, charge_max: string, charge_gross: string, charge_cash: string) => {
    const newcontext = copyContext();
    //if (newcontext.charge_min == charge_min && newcontext.charge_max == charge_max && newcontext.charge_gross == charge_gross && newcontext.charge_cash == charge_cash) {
    //  return;
    //}

    newcontext.charge_min = charge_min;
    newcontext.charge_max = charge_max;
    newcontext.charge_gross = charge_gross;
    newcontext.charge_cash = charge_cash;

    setContext(newcontext);
  }

  const saveCsvCharges = (headers: string, data: string, charge_min: string, charge_max: string, charge_gross: string, charge_cash: string, hasSetting: boolean, hasBillingClass: boolean, hasModifiers: boolean, hasNotes: boolean) => {
    const newcontext = copyContext();
    newcontext.chargeHeaders = headers;
    newcontext.chargeData = data;
    newcontext.charge_min = charge_min;
    newcontext.charge_max = charge_max;
    newcontext.charge_gross = charge_gross;
    newcontext.charge_cash = charge_cash;
    newcontext.hasSetting = hasSetting;
    newcontext.hasBillingClass = hasBillingClass;
    newcontext.hasModifiers = hasModifiers
    newcontext.hasNotes = hasNotes;

    setContext(newcontext);
  }

  const saveFacilityMatches = (matches: IFacilityInterface[], error?: string) => {
    const newcontext = copyContext();
    newcontext.matches = matches;

    // Use the same list for the filtered matches
    newcontext.filteredMatches = matches;

    // Remove the item and charges
    //HACK CHECK
    //newcontext.selectedItem = undefined;
    //newcontext.itemMatches = [];
    //newcontext.chargeMatches = [];

    UtilsService.consoledev('saveFacilityMatches', newcontext.matches.length);

    setContext(newcontext);
  }

  const saveRecentMatches = (recentMatches: IFacilityInterface[], error?: string) => {
    const newcontext = copyContext();

    // This is also performed when a new facility is selected. This routine is not needed?

    // Make sure all recentmatches are labeled with recent = true
    const copy: IFacilityInterface[] = []
    for (const match of recentMatches) {
      const rm = {...match};
      rm.recent = true;
      copy.push(rm);
    }

    newcontext.recentMatches = copy;

    setContext(newcontext);
  }

  /**
   * Filter the facilities using the specified filter. Recent facilities are added to
   * the beginning of the list.
   * @param filter {string} The current filter
   */
  const updateFacilityFilter = (newcontext: IMatchInterface, filter: string) => {
    // Compute the filtered list
    const lcfilter = filter.toLowerCase();
    let filteredMatches = newcontext.matches.filter(x => x.name.toLowerCase().includes(lcfilter) || x.zip.includes(lcfilter) || x.city.toLowerCase().includes(lcfilter));

    //console.log('updateFacilityFilter', newcontext.recentMatches.length);
    // Remove the recent matches from these matches, and add the recent to the beginning
    filteredMatches = filteredMatches.filter(x => !newcontext.recentMatches.includes(x));
    filteredMatches.unshift(...newcontext.recentMatches);

    newcontext.filteredMatches = filteredMatches;
    UtilsService.consoledev(`filtered ${newcontext.matches.length} to ${filteredMatches.length} (${selectedFacility})`);
  }


  const saveFacilityFilter = (filter: string) => {
    UtilsService.consoledev(`saveFacilityFilter: ${filter}, ${context?.facilityFilter}, ${context?.selectedFacility}`);
    if (!context?.facilityFilter || context?.facilityFilter !== filter) {
      UtilsService.consoledev('applying context');
      const newcontext = copyContext();
      newcontext.facilityFilter = filter;

      // Filter the list and include recent facilities
      updateFacilityFilter(newcontext, filter);

      // Remove the selectedFacility
      newcontext.selectedFacility = undefined;
      newcontext.error = '';

      // Remove the item and charges (unless they are already empty)
      newcontext.selectedItem = undefined;
      if (newcontext.itemMatches?.length > 0) {
        newcontext.itemMatches = [];
      }
      if (newcontext.chargeMatches?.length > 0) {
        newcontext.chargeMatches = [];
      }

      UtilsService.consoledev('setting', newcontext);
      setContext(newcontext);
    }
  }

  const clearFacility = () => {
    const newcontext = copyContext();
    newcontext.facilityFilter = '';
    newcontext.filteredMatches = newcontext.matches;
    newcontext.selectedFacility = undefined;
    newcontext.selectedItem = undefined;
    newcontext.itemState = '';
    newcontext.itemMatches = [];
    newcontext.chargeMatches = [];
    newcontext.chargeState = '';

    newcontext.error = '';

    // Filter the list and include recent facilities
    updateFacilityFilter(newcontext, newcontext.facilityFilter);

    UtilsService.consoledev('clearFacility', newcontext);
    setContext(newcontext);
  }

  const clearItem = () => {
    const newcontext = copyContext();
    newcontext.selectedItem = undefined;
    newcontext.itemState = '';
    newcontext.itemMatches = [];
    newcontext.chargeMatches = [];
    newcontext.chargeState = '';

    newcontext.error = '';

    UtilsService.consoledev('clearItem', newcontext);
    setContext(newcontext);
  }

  const saveItemMatches = (itemMatches: IItemInterface[], error?: string) => {
    //console.log('saveItemMatches');
    const newcontext = copyContext();
    newcontext.itemMatches = itemMatches;
    newcontext.itemState = 'fetched';

    // Remove the charges
    newcontext.chargeState = '';
    if (newcontext.chargeMatches?.length > 0) {
      newcontext.chargeMatches = [];
    }

    // Show the results if there are any
    if (itemMatches.length > 0) {
      //console.log('itemMatches has length', itemMatches.length, " showItems");
      newcontext.showItems = true;
    }

    setContext(newcontext);
  }

  const saveSelectedFacility = (selectedFacility?: IFacilityInterface) => {
    UtilsService.consoledev(`saveSelectedFacility: ${selectedFacility}`);

    const newcontext = copyContext();
    newcontext.selectedFacility = selectedFacility;

    newcontext.itemState = 'pending';
    newcontext.itemMatches = [];
    newcontext.chargeMatches = [];
    newcontext.chargeState = '';

    newcontext.error = '';

    // Track the last N selected facilities, with the most recent at the front
    if (selectedFacility) {
      const existing = newcontext.recentMatches.find(x => x.id === selectedFacility.id);
      if (!existing) {
        const selected = {...selectedFacility};
        selected.recent = true;
        newcontext.recentMatches.unshift(selected);
        while (newcontext.recentMatches.length > 3) {
          newcontext.recentMatches.pop();
        }
      }
    }

    setContext(newcontext);
  }

  const saveSelectedItem = (selectedItem?: IItemInterface) => {
    UtilsService.consoledev(`saveSelectedItem: ${JSON.stringify(selectedItem)}`);

    const newcontext = copyContext();
    if (selectedItem?.item_id == newcontext.selectedItem?.item_id) {
      // No change so stop. There are side effects to changing the item
      // matches and chargestate are reset
      UtilsService.consoledev('same item selected');
      newcontext.showItems = false;
      setContext(newcontext);
      return;
    }
      //saveMatches(matchFacilitySearch, matchDescriptionSearch, matchCodeSearch, matches, itemMatches, []);
      //saveChargeState('pending');


    newcontext.selectedItem = selectedItem;
    newcontext.showItems = false;
    if (newcontext.chargeMatches?.length > 0) {
      newcontext.chargeMatches = [];
    }

    // Update the currently selected search values (if changed)
    const description = selectedItem ? selectedItem.item_description : '';
    const code = selectedItem ? selectedItem.item_code : '';
    if (newcontext.matchDescriptionSearch !== description) {
      newcontext.matchDescriptionSearch = description;
    }
    if (newcontext.matchCodeSearch !== code) {
      newcontext.matchCodeSearch = code;
    }

    if (newcontext.selectedFacility && newcontext.selectedItem) {
      // Set state so the charges to retrieved
      // Prevent multiple fetches at once
      //console.log('pre chargeState', newcontext.chargeState);
      if (newcontext.chargeState != 'fetching') {
        //console.log('changing', newcontext.chargeState, 'to pending')
        newcontext.chargeState ='pending';
      }

    }

    setContext(newcontext);
  }

  const saveChargeState = (chargeState: string) => {
    UtilsService.consoledev(`saveChargeState: ${chargeState}`);
    const newcontext = copyContext();
    newcontext.chargeState = chargeState;
    setContext(newcontext);
  }

  const saveItemState = (itemState: string) => {
    // UtilsService.consoledev(`saveItemState: ${itemState}`);
    const newcontext = copyContext();
    newcontext.itemState = itemState;
    setContext(newcontext);
  }

  const saveShowItems = (itemState: boolean) => {
    UtilsService.consoledev(`saveShowItems: ${itemState}`);
    const newcontext = copyContext();
    newcontext.showItems = itemState;
    setContext(newcontext);
  }

  const saveShowGraph = (show: boolean) => {
    UtilsService.consoledev(`saveShowGraph: ${itemState}`);
    const newcontext = copyContext();
    newcontext.showgraph = show;
    setContext(newcontext);
  }

  const saveView = (stateview: string | undefined, listview: boolean, mapview: boolean, welcome: boolean) => {
    //UtilsService.consoledev('saveView', search, searchState);
    const newcontext: IMatchInterface = {
      stateview,
      listview,
      mapview,
      welcome,
      welcomeState,
      matchFacilitySearch: context ? context.matchFacilitySearch : '',
      matchDescriptionSearch: context ? context.matchDescriptionSearch : '',
      matchCodeSearch: context ? context.matchCodeSearch : '',
      filter_setting: context ? context.filter_setting : 'ip,op',
      filter_billingclass: context ? context.filter_billingclass : 'facility,professional',
      filter_modifiers: context ? context.filter_modifiers : 'modifier',
      //facilityid: context ? context.facilityid : '',
      matchState: context ? context.matchState : ConstantService.negotiated_charge_region,
      matches: context ? context.matches : [],
      filteredMatches: context ? context.filteredMatches : [],
      recentMatches: context ? context.recentMatches : [],
      facilityFilter: context ? context.facilityFilter : '',
      itemMatches: context ? context.itemMatches : [],
      chargeMatches: context ? context.chargeMatches : [],
      itemState: context ? context.itemState : '',
      showItems: context ? context.showItems : false,
      chargeHeaders: context ? context.chargeHeaders : '',
      chargeData: context ? context.chargeData : '',
      hasSetting: context ? context.hasSetting : true,
      hasBillingClass: context ? context.hasBillingClass : true,
      hasModifiers: context ? context.hasModifiers : true,
      hasNotes: context ? context.hasNotes : true,
      charge_min: context ? context.charge_min : '',
      charge_max: context ? context.charge_max : '',
      charge_gross: context ? context.charge_gross : '',
      charge_cash: context ? context.charge_cash : '',
      chargeState: context ? context.chargeState : '',
      selectedFacility: context ? context.selectedFacility : undefined,
      selectedItem: context ? context.selectedItem : undefined,
      error: context ? context.error : '',
      showgraph: context ? context.showgraph : true,
      saveWelcomeState: context ? context.saveWelcomeState : default0,
      saveMatches: context ? context.saveMatches : default4,
      saveChargeMatches: context ? context.saveChargeMatches : default4a,
      saveCharges: context ? context.saveCharges : default4a1,
      saveCsvCharges: context ? context.saveCsvCharges : default4a2,
      saveFacilityMatches: context ? context.saveFacilityMatches : default4b,
      saveRecentMatches: context ? context.saveRecentMatches : default4b,
      saveFacilityFilter: context ? context.saveFacilityFilter : default0,
      clearFacility: context ? context.clearFacility : default0b,
      clearItem: context ? context.clearItem : default0b,
      saveItemMatches: context ? context.saveItemMatches : default4c,
      saveView: context ? context.saveView : default3,
      saveSelectedFacility: context ? context.saveSelectedFacility : default5,
      saveSelectedItem: context ? context.saveSelectedItem : default6,
      saveChargeState: context ? context.saveChargeState : default0,
      saveItemState: context ? context.saveItemState : default0,
      saveShowItems: context ? context.saveShowItems : default0a,
      saveSearchParameters: context ? context.saveChargeState : default7,
      saveFacilitySearch: context ? context.saveFacilitySearch : default0,
      saveDescriptionSearch: context ? context.saveDescriptionSearch : default0,
      saveCodeSearch: context ? context.saveCodeSearch : default0,
      saveFilterSetting: context ? context.saveFilterSetting: default0,
      saveFilterBillingClass: context ? context.saveFilterBillingClass: default0,
      saveFilterModifiers: context ? context.saveFilterModifiers: default0,
      saveShowGraph: context ? context.saveShowGraph: default0a
    };
    setContext(newcontext);    
  }

  const matchFacilitySearch = context ? context.matchFacilitySearch : '';
  const matchDescriptionSearch = context ? context.matchDescriptionSearch : '';
  const matchCodeSearch = context ? context.matchCodeSearch : '';
  const filter_setting = context ? context.filter_setting : 'ip,op';
  const filter_billingclass = context ? context.filter_billingclass : 'facility,professional';
  const filter_modifiers = context ? context.filter_modifiers : 'modifier';
  //const facilityid = context ? context.facilityid : '';
  const matchState = ConstantService.negotiated_charge_region;
  const matches = context ? context.matches : [];
  const filteredMatches = context ? context.filteredMatches : [];
  const recentMatches = context ? context.recentMatches : [];
  const facilityFilter = context ? context.facilityFilter : '';
  const itemMatches = context ? context.itemMatches : [];
  const chargeMatches = context ? context.chargeMatches : [];
  const itemState = context ? context.itemState : '';
  const showItems = context ? context.showItems : false;
  const chargeHeaders = context ? context.chargeHeaders : '';
  const chargeData = context ? context.chargeData : '';
  const hasSetting = context ? context.hasSetting : true;
  const hasBillingClass = context ? context.hasBillingClass : true;
  const hasModifiers = context ? context.hasModifiers : true;
  const hasNotes = context ? context.hasNotes : true;
  const charge_min = context ? context.charge_min : '';
  const charge_max = context ? context.charge_max : '';
  const charge_gross = context ? context.charge_gross : '';
  const charge_cash = context ? context.charge_cash : '';
  const chargeState = context ? context.chargeState : '';
  const selectedFacility = context ? context.selectedFacility : undefined;
  const selectedItem = context ? context.selectedItem : undefined;
  const error = context ? context.error : '';
  const showgraph = context ? context.showgraph : true;
  const sv = ConstantService.getStateView();
  const stateview = sv ? sv : (context ? context.stateview : undefined);
  const listview = true; // sv ? true : (context ? context.listview : false);
  const mapview = sv ? true : (context ? context.mapview : false);
  const welcome = sv ? false : (context ? context.welcome : true);
  const welcomeState = context ? context.welcomeState : '';

  return <MatchContext.Provider value={{ stateview, listview, mapview, welcome, welcomeState, matchFacilitySearch, matchDescriptionSearch, matchCodeSearch, filter_setting, filter_billingclass, filter_modifiers, matchState, matches, filteredMatches, recentMatches, facilityFilter, itemMatches, chargeMatches, itemState, showItems, chargeHeaders, chargeData, hasSetting, hasBillingClass, hasModifiers, hasNotes, charge_min, charge_max, charge_gross, charge_cash, chargeState, selectedFacility, selectedItem, error, showgraph, saveWelcomeState, saveMatches, saveChargeMatches, saveCharges, saveCsvCharges, saveFacilityMatches, saveRecentMatches, saveFacilityFilter, clearFacility, clearItem, saveItemMatches, saveView, saveSelectedFacility, saveSelectedItem, saveChargeState, saveShowItems, saveItemState, saveSearchParameters, saveFacilitySearch, saveDescriptionSearch, saveCodeSearch, saveFilterSetting, saveFilterBillingClass, saveFilterModifiers, saveShowGraph }}>{children}</MatchContext.Provider>;
};  
  
export default AppMatchProvider;
