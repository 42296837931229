// IntroText.tsx
// Intro text and warranty used on Welcome page (see IntroStateText for stateview)

export const IntroText = () => {
  const region_name: string = (window as any).env['NEGOTIATED_CHARGE_REGION_NAME'] || '';
  return (
    <div className="header-text-wrapper">
    <p className="header-text">
      <a target="_blank" href="https://www.patientrightsadvocate.org/" className="header-text-link">PatientRightsAdvocate.org</a> (PRA) aggregated publicly available price transparency data for all {region_name} hospitals and created the {region_name} Hospital Price Finder to empower all Americans with actual, upfront hospital prices. We are working on behalf of patients, employers, unions, and workers in the fight for systemwide healthcare price transparency to reduce healthcare costs through a functional, competitive market.
    </p>
    <p className="header-text">Because of the scope and constantly changing nature of hospital prices, PRA makes no warranty, and expressly disclaims all warranties, as to the accuracy or completeness of the files and other information provided. Price information should always be verified directly with hospitals and service providers. All use of this site and database is governed by our <a href="https://www.patientrightsadvocate.org/termsofservice">Terms of Service</a>.
    </p>   
    </div>

  );
};



