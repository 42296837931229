// Welcome.tsx
import React, { useState } from 'react';
import { useAsync, useAsyncCallback } from "react-async-hook";
import { ISearchInterface, IMatchInterface, IFacilityInterface } from '../../@types/context';
import { SearchContext } from '../context/SearchContext';
import { MatchContext } from '../context/MatchContext';
import { IntroText } from './IntroText';
import ConstantService from '../../services/constant.service';

import ClickableUSAMap from '../USAState/ClickableUSAMap';

function delay(ms: number) {
  return new Promise( resolve => setTimeout(resolve, ms) );
}

export const Welcome = () => {
  const { sessionid, saveSearch } = React.useContext(SearchContext) as ISearchInterface;

  const { stateview, welcome, welcomeState, saveWelcomeState, saveView } = React.useContext(MatchContext) as IMatchInterface;

  const onclickasync = async function() {
    saveView(ConstantService.negotiated_charge_region, true, false, false);

    // UtilsService.consoledev('onclick', welcomeState, state)
  }
  const acceptclick = useAsyncCallback(onclickasync);

  if (!sessionid || !welcome) {
    return (
      null
    );
  }

  // Region information is injected by env.js (see public)
  const region_name = (window as any).env.NEGOTIATED_CHARGE_REGION_NAME || '';

  return (
    <div>
      <section className="section_hero">
        <div className="container">
          <h1>{region_name} Hospital Price Finder</h1>
          <div className="background-color-white">
            <div className="text-display-inline">
              <IntroText />
              <br/><br/>
              <center style={{fontSize: 'larger'}}>
                <input type="checkbox" onClick={evt => {acceptclick.execute()}}/>&nbsp;<b>I agree</b>, continue to site
              </center>
  
            </div>
          </div>
        </div>
      </section>
    </div>
  );


};



